import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import CodeWord from "./components/CodeWord";
import { useState } from "react";
import RowColCountSelector from "./components/RowColCountSelector";

function App() {
  const [rowCount, setRowCount] = useState(null);
  const [colCount, setColCount] = useState(null);

  function handleClickRowColCount(rowCount, colCount) {
    setRowCount(rowCount);
    setColCount(colCount);
  }

  return (
    <div className="App">
      <h1>Code Word Helper</h1>
      <div>
        {rowCount !== null && colCount !== null ? (
          <CodeWord rowCount={rowCount} colCount={colCount} />
        ) : (
          <RowColCountSelector
            handleClickRowColCount={handleClickRowColCount}
          />
        )}
      </div>
    </div>
  );
}

export default App;
