function NumberSquare(props) {
  return (
    <button
      className={`d-flex flex-column justify-content-start ${
        props.selected ? "Square Square-selected" : "Square"
      }`}
      onClick={() => props.handleClick(props.number)}
    >
      <div className={"align-self-start Square-Number"}>{props.number}</div>
      <div>{props.letter !== null ? props.letter : ""}</div>
    </button>
  );
}

function NumberRow(props) {
  const columns = [];

  let i;
  for (i = props.lowestNumber; i < props.lowestNumber + 13; i++) {
    columns.push(
      <NumberSquare
        key={i}
        number={i}
        handleClick={props.handleNumberSquareClick}
        selected={props.selectedNumber === i}
        letter={props.numberToLetterDict[i]}
      />
    );
  }

  return <div className={"d-flex flex-row"}>{columns}</div>;
}

export default function NumbersBoard(props) {
  return (
    <>
      <NumberRow
        lowestNumber={1}
        handleNumberSquareClick={props.handleNumberSquareClick}
        selectedNumber={props.selectedNumber}
        numberToLetterDict={props.numberToLetterDict}
      />
      <NumberRow
        lowestNumber={14}
        handleNumberSquareClick={props.handleNumberSquareClick}
        selectedNumber={props.selectedNumber}
        numberToLetterDict={props.numberToLetterDict}
      />
    </>
  );
}
