import Board from "./Board";
import { useState } from "react";
import { Button } from "react-bootstrap";
import NumbersBoard from "./NumbersBoard";
import LettersBoard from "./LettersBoard";

function CodeWordInstructions(props) {
  if (props.boardState === "edit") {
    return (
      <div className={"text-left"}>
        <p>
          Currently in edit mode. In this mode you can create a code word board
          to solve. Click on a number and then click on the board squares that
          you would like to be that tagged with that number. Click again to
          remove the number tag or click the square with a different number to
          change the number tag.
        </p>
        <Button onClick={() => props.handleToggleStateClick()}>
          Enable Game Mode
        </Button>
      </div>
    );
  }
  return (
    <div className={"text-left"}>
      <p>
        Currently in {props.boardState} mode. In this mode you can solve the
        code word board. Click a number and then click on a letter to associate
        the letter with the selected number. Click the letter again to remove
        the letter association or click a different letter to change it.
      </p>
      <Button onClick={() => props.handleToggleStateClick()}>
        Enable Edit Mode
      </Button>
    </div>
  );
}

export default function CodeWord(props) {
  const initialBoardNumbersArray = new Array(props.rowCount);
  for (let i = 0; i < props.rowCount; i++) {
    initialBoardNumbersArray[i] = new Array(props.colCount).fill(null);
  }

  const initialNumberToLetterDict = {};
  for (let i = 1; i < 27; i++) {
    initialNumberToLetterDict[i] = null;
  }

  const [boardState, setBoardState] = useState("edit");
  const [boardNumbersArray, setBoardNumbersArray] = useState(
    initialBoardNumbersArray
  );
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [numberToLetterDict, setNumberToLetterDict] = useState(
    initialNumberToLetterDict
  );

  function handleToggleStateClick() {
    if (boardState === "edit") {
      setBoardState("game");
    } else if (boardState === "game") {
      setBoardState("edit");
    }
  }

  function handleSquareClickEditMode(rowNumber, colNumber) {
    const newBoardNumbersArray = [...boardNumbersArray];
    const currentNumber = newBoardNumbersArray[rowNumber][colNumber];

    if (currentNumber === selectedNumber) {
      newBoardNumbersArray[rowNumber][colNumber] = null;
    } else {
      newBoardNumbersArray[rowNumber][colNumber] = selectedNumber;
    }

    setBoardNumbersArray(newBoardNumbersArray);
  }

  function handleSquareClickGameMode(rowNumber, colNumber) {
    const newSelectedNumber = boardNumbersArray[rowNumber][colNumber];

    if (newSelectedNumber !== null) {
      setSelectedNumber(newSelectedNumber);
    }
  }

  function handleSquareClick(rowNumber, colNumber) {
    if (boardState === "edit") {
      handleSquareClickEditMode(rowNumber, colNumber);
      return;
    } else if (boardState === "game") {
      handleSquareClickGameMode(rowNumber, colNumber);
      return;
    }
    throw new Error();
  }

  function handleNumberSquareClick(number) {
    setSelectedNumber(number);
  }

  function handleLetterSquareClick(letter) {
    if (boardState === "edit" || selectedNumber === null) {
      return;
    }

    const newNumberToLetterDict = {};
    Object.assign(newNumberToLetterDict, numberToLetterDict);

    if (numberToLetterDict[selectedNumber] === letter) {
      newNumberToLetterDict[selectedNumber] = null;
    } else {
      newNumberToLetterDict[selectedNumber] = letter;
      const numberWithLetter = Object.keys(numberToLetterDict).find(
        (key) => numberToLetterDict[key] === letter
      );
      if (numberWithLetter) {
        newNumberToLetterDict[numberWithLetter] = null;
      }
    }

    setNumberToLetterDict(newNumberToLetterDict);
  }

  function clearAllLetters() {
    setNumberToLetterDict(initialNumberToLetterDict);
  }

  return (
    <div>
      <div
        className={
          "d-flex flex-row flex-wrap justify-content-center overflow-scroll"
        }
      >
        <div className={"d-flex order-2 order-lg-1"}>
          <div className={"mx-auto"}>
            <Board
              rowCount={props.rowCount}
              colCount={props.colCount}
              boardState={boardState}
              handleSquareClick={handleSquareClick}
              numbersArray={boardNumbersArray}
              selectedNumber={selectedNumber}
              numberToLetterDict={numberToLetterDict}
            />
          </div>
        </div>
        <div
          className={
            "d-flex flex-column px-3 justify-content-center align-items-center order-1 order-lg-2 mb-2 mb-lg-0"
          }
        >
          <div className={"pb-5"}>
            <CodeWordInstructions
              boardState={boardState}
              handleToggleStateClick={handleToggleStateClick}
            />
          </div>
          <div className={"pb-2"}>
            <NumbersBoard
              handleNumberSquareClick={handleNumberSquareClick}
              selectedNumber={selectedNumber}
              numberToLetterDict={numberToLetterDict}
            />
          </div>
          <div className={"pb-2"}>
            <LettersBoard
              handleLetterSquareClick={handleLetterSquareClick}
              numberToLetterDict={numberToLetterDict}
            />
          </div>
          <Button
            className={"align-self-start"}
            disabled={boardState !== "game"}
            onClick={() => clearAllLetters()}
          >
            Clear All Letters
          </Button>
        </div>
      </div>
    </div>
  );
}
