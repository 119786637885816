import { Button, Alert } from "react-bootstrap";
import { useState } from "react";

export default function RowColCountSelector(props) {
  const [rowCount, setRowCount] = useState(null);
  const [colCount, setColCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  function handleClickRowColCount(rowCount, colCount) {
    if (isNaN(rowCount) || rowCount < 1) {
      setErrorMessage(
        "Invalid row count. Please enter a number greater than 1."
      );
      return;
    }
    if (isNaN(colCount) || colCount < 1) {
      setErrorMessage(
        "Invalid column count. Please enter a number greater than 1."
      );
      return;
    }

    const maxBoardLength = 30;

    if (colCount > maxBoardLength || rowCount > maxBoardLength) {
      setErrorMessage(
        `That's quite a big board, the maximum number of rows/columns is ${maxBoardLength}`
      );
      return;
    }

    props.handleClickRowColCount(rowCount, colCount);
  }

  return (
    <div className={"d-flex flex-column"}>
      {errorMessage !== null ? (
        <Alert variant={"danger"}>{errorMessage}</Alert>
      ) : (
        ""
      )}
      <div
        className={
          "d-flex flex-row align-items-center justify-content-between pb-4"
        }
      >
        <div className={"pr-2"}>Number of Rows:</div>
        <input
          name="Number of Rows"
          type="number"
          onChange={(event) => setRowCount(parseInt(event.target.value))}
          min={1}
        />
      </div>

      <div
        className={
          "d-flex flex-row align-items-center justify-content-between pb-4"
        }
      >
        <div className={"pr-2"}>Number of Columns:</div>
        <input
          name="Number of Columns"
          type="number"
          onChange={(event) => setColCount(parseInt(event.target.value))}
          min={1}
        />
      </div>
      <Button onClick={() => handleClickRowColCount(rowCount, colCount)}>
        Submit
      </Button>
    </div>
  );
}
