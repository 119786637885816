import "./Square.css";

export default function Square(props) {
  if (props.number === null) {
    return (
      <button
        className={"Square Black-Square"}
        onClick={() => props.handleClick(props.rowNumber, props.colNumber)}
      />
    );
  }
  return (
    <button
      className={`d-flex flex-column justify-content-start ${
        props.selected ? "Square Square-selected" : "Square"
      }`}
      onClick={() => props.handleClick(props.rowNumber, props.colNumber)}
    >
      <div className={"align-self-start Square-Number"}>{props.number}</div>
      <div>{props.letter !== null ? props.letter : ""}</div>
    </button>
  );
}
