import Square from "./Square";

function BoardRow(props) {
  const columns = [];

  let i;
  for (i = 0; i < props.colCount; i++) {
    columns.push(
      <Square
        key={i}
        rowNumber={props.rowNumber}
        colNumber={i}
        handleClick={props.handleSquareClick}
        number={props.numbers[i]}
        selected={
          (props.numbers[i] !== null) &
          (props.selectedNumber === props.numbers[i])
        }
        letter={
          props.numbers[i] !== null
            ? props.numberToLetterDict[props.numbers[i]]
            : null
        }
      />
    );
  }

  return <div className={"d-flex flex-row"}>{columns}</div>;
}

export default function Board(props) {
  const rows = [];

  let i;
  for (i = 0; i < props.rowCount; i++) {
    rows.push(
      <BoardRow
        key={i}
        colCount={props.colCount}
        rowNumber={i}
        handleSquareClick={props.handleSquareClick}
        numbers={props.numbersArray[i]}
        selectedNumber={props.selectedNumber}
        numberToLetterDict={props.numberToLetterDict}
      />
    );
  }
  return <div className={"d-flex flex-column"}>{rows}</div>;
}
