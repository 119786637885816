function LetterSquare(props) {
  return (
    <button
      className={"Square"}
      onClick={() => props.handleClick(props.letter)}
    >
      {props.letter}
      {props.crossedOut ? <div className={"Square-Diag-Line"} /> : ""}
    </button>
  );
}

function LetterRow(props) {
  return (
    <div className={"d-flex flex-row"}>
      {props.letters.map((letter) => (
        <LetterSquare
          key={letter}
          letter={letter}
          handleClick={props.handleLetterSquareClick}
          crossedOut={Object.values(props.numberToLetterDict).includes(letter)}
        />
      ))}
    </div>
  );
}

export default function LettersBoard(props) {
  return (
    <>
      <LetterRow
        letters={[
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
        ]}
        handleLetterSquareClick={props.handleLetterSquareClick}
        numberToLetterDict={props.numberToLetterDict}
      />
      <LetterRow
        letters={[
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ]}
        handleLetterSquareClick={props.handleLetterSquareClick}
        selectedNumber={props.selectedNumber}
        numberToLetterDict={props.numberToLetterDict}
      />
    </>
  );
}
